const config = {
  rootProjectId: "root",
  uiBucketName: "alshaya.c.retter.io",
  appUrl: "https://api.alshaya.retter.io/",
  cosUrl: "api.alshaya.retter.io",
  version: "2.1.23",
  captchaKey: "6Lc5SXApAAAAABV67yIt4smzkcb3qXogn_PP829e",
  stage: "PROD"
}
export default config

